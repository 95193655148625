import React from 'react'
import Discover from "./home/discover/Discover"
import Hero from "./home/hero/Hero"
import Homes from "./home/mainContent/homes/Home"
import Header from "./common/header/Header"
import Navbar from './Navbar'
import News from './News'
import Head from './common/header/Head'
import Footer from './common/footer/Footer'

function HomePage() {
  return (
    <>
      <Navbar/>
      <Head />
      <Hero />
      <Homes />
      {/* <News/> */}
      {/* <Discover /> */}
      <Footer/>
    </>
  )
}

export default HomePage
